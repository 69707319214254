import { ArrowButton, IDirection } from "../widgets/ArrowButton/ArrowButton";
import React, { useEffect, useRef } from "react";
import QuoteRed from "../../assets/icons/red/QuoteRed.svg";
import Slider from "react-slick";
import "./TestimonialSlider.scss";
import "../../utils/slick.scss";
import "../../utils/slick-theme.scss";

interface ITestimonial {
  main: string;
  author: string;
  details: string;
}

const testimonials: ITestimonial[] = [
  {
    main: `AllBright took our challenging designs and really made them shine.
    They understand design, communicate clearly and sweat the details.
    The result was better than what we had hoped for. Working with 
    them is a blast!`,
    author: "Christoph Kolb",
    details: "CDO Wahnsinn Design GmbH, wahnsinn.design",
  },
  {
    main: `Working with AllBright.io to create our mobile app was 
    an absolute pleasure. They are professional, knowledgeable, 
    and easy to work with. Thanks AllBright team!`,
    author: "Angel Lorente",
    details: "Founder & CEO of Fintech Connector, fintechconnector.com",
  },
  {
    main: `AllBright is our long-standing partner for the technical 
    analysis of healthtech products. They help us to evaluate 
    the feasibility of the technological solution and build 
    prototypes very quickly. We find their support invaluable.`,
    author: "Assoc. Prof. Dr. Dr. Jedrzej Kosiuk",
    details: "Healthtech expert and Investor",
  },
];

interface ITestimonialSliderProps {
  page: string;
  title?: string;
  hideHeader?: boolean;
  hideMobile?: boolean;
}

export function TestimonialSlider({
  page,
  title,
  hideHeader,
  hideMobile,
}: ITestimonialSliderProps) {
  const slider: any = useRef(null);

  useEffect(() => {
    slider.current.slickPlay();
  }, [slider.current, page]);

  const handleNextClick = () => {
    slider.current.slickNext();
  };

  const handlePreviousClick = () => {
    slider.current.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className={`testimonials-slider ${hideMobile ? "hide-mobile" : ""}`}>
      {!hideHeader && (
        <div className="header">
          <h2>{title ? title : "Clients about us"}</h2>
          <div className="arrowButtonContainer">
            <ArrowButton
              direction={IDirection.Left}
              handleClick={handlePreviousClick}
            />
            <ArrowButton
              direction={IDirection.Right}
              handleClick={handleNextClick}
            />
          </div>
        </div>
      )}
      <Slider ref={slider} {...settings}>
        {testimonials.map((testimonial: ITestimonial, index) => {
          const { main, author, details } = testimonial;
          const SlideProps = {
            main,
            author,
            details,
          };
          return <Slide key={`slide-${index}`} {...SlideProps} />;
        })}
      </Slider>
    </div>
  );
}

const Slide = ({ main, author, details }: ITestimonial) => {
  return (
    <div className="testimonial">
      <span className="testimonial__main">{main}</span>
      <span className="testimonial__author">{author}</span>
      <span className="testimonial__author-details">{details}</span>
    </div>
  );
};
