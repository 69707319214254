import "./ArrowButton.scss";

import ArrowIcon from "../../../assets/Arrow-horizontal.svg";
import React from "react";

export enum IDirection {
  Up = "up",
  Down = "down",
  Left = "left",
  Right = "right",
}
interface IArrowButton {
  direction: IDirection;
  handleClick: any;
  isMini?: boolean;
}

export function ArrowButton({ direction, handleClick, isMini }: IArrowButton) {
  const buttonClass =
    isMini && isMini ? `mini ${direction}` : `regular ${direction}`;
  return (
    <button onClick={handleClick} className={buttonClass} aria-label={direction}>
      <ArrowIcon alt="" />
    </button>
  );
}
